import React from 'react';
import platformImg from '../../../picture/businessSence/pay.png';
import systemImg1 from '../../../picture/businessSence/parkManagement.png';
import systemImg2 from '../../../picture/businessSence/smartCity.png';
import systemImg3 from '../../../picture/businessSence/rentalManagement.png';

const styles = require('./smartSolutionDescribe.module.scss');
export default function SmartSolutionDescribe() {
    const describeInfo = {
        solutionTitle: '方案概述',
        solutionDescribe:
            '"智能社区安防系统"按照"统一规划、统一标准、统一平台、统一管理"的设计思路，通过社区内的视频监控、微卡口、人脸门禁和各类物联感知设备，实现社区数据、事件的全面感知，并充分运用大数据、人工智能、物联网等新技术，建设以大数据智能应用为核心的"智能安防社区系统",形成了公安、综治、街道、物业多方联合的立体化社区防控体系，有效提升了特殊人群、重点关注、涉案、涉X等人员的管理能力，不断提高公安、综治等政府机关的预测预警和研判能力、精确打击能力和动态管理能力，提升社区防控智能化水平，提升居民居住幸福指数。',
        platformTitle: '智能安防社区平台',
        platformDescribe:
            '通过对海量社区感知数据和业务数据的云存储、弹性计算以及数据治理，形成各种主题库、专题库和技X法模型，为公安、综治、居民、街道、物业等多方用户提供个性化应用平台管控，多方管理降风险一个平台对多区域统一管理，集实时监测、报警、监管、信息管理为一体，小区业主、物业、消防监管部门、派出所等可共同监控消防安全状况，发生警情，快速定位报警地点，确认险情，第一时间采取有效措施，最大限度降低危害。',
        platformImg: platformImg,
        systemImg: [systemImg1, systemImg2, systemImg3],
        systemTitle: '智能小区门禁系统',
        systemDescribeMember: [
            '住户第一次进出时，手持身份证进行身份识别加上人脸比对，系统后台将会把信息保存在本地平台，然后推送到公安系统的数据库进行比对。如果一旦发现社会在逃可疑人员，系统将自动通知辖区民警。不仅保护了社区的安全，还为公安执法部门提供有用的线索。 ',
            '居民先到物业进行身份登记和人脸登记，或用社区移动应用自助注册登记人脸信息，每当居民进入拍摄范围，前端摄像头便能够自动抓拍，经过服务器对人脸数据进行特征提取，并完成身份确认后打开门禁，整个交互过程流畅自然，用时仅需几秒钟。通过系统大数据信息和人脸识别系，可以快速有效地对外地返回人员、本地籍外出返回人员等进行实时有效的出行轨迹分析。',
            '一房一档模块根据小区、楼栋号、房间号进行搜索，展示某一户的综合信息，包含户成员、户主关系、户内车辆信息、水电煤用量等。一车一档模块通过采集和汇聚车辆基本信息、小区微卡口过车记录、停车场记录，并结合公安卡口识别信息，综合展示车辆的整体情况、轨迹信息等。轨迹分析模块通过输入一张人脸照片、动态感知数据、车牌号码，查询其在指定时间段内的通行记录，在地图上按照时间先后顺序进行展示，精准的刻画对象的活动轨迹。社区大数据分析模型模块基于社区层面汇聚的感知数据、业务数据，构建多种贴近社区实战应用的技战法模型，主要包括以人为主的社区实有人口分析模型、社区重点人员分析模型、漏管失控分析模型等，以及以车辆为主的往返区域分析模型、违法占道分析模型、频繁出没分析模型等十多种社区大数据分析模型。',
        ],
    };

    return (
        <div className={styles.mainContainer}>
            <p className={styles.solutionTitle}>{describeInfo.solutionTitle}</p>
            <p className={styles.solutionDescribe}>
                {describeInfo.solutionDescribe}
            </p>
            <div className={styles.platformContainer}>
                <img
                    src={describeInfo.platformImg}
                    alt={describeInfo.platformTitle}
                />
                <div className={styles.platformRightContainer}>
                    <p className={styles.platformTitle}>
                        {describeInfo.platformTitle}
                    </p>
                    <p className={styles.platformDescrible}>
                        {describeInfo.platformDescribe}
                    </p>
                </div>
            </div>
            <div className={styles.systemImgContainer}>
                {describeInfo.systemImg.map((item, index) => {
                    return (
                        <img src={item} key={item + index} alt="systemImg" />
                    );
                })}
            </div>
            <div className={styles.systemContainer}>
                <p className={styles.systemTitle}>{describeInfo.systemTitle}</p>
                <div className={styles.systemMapContainer}>
                    {describeInfo.systemDescribeMember.map((item, index) => {
                        return (
                            <p
                                className={styles.systemDescribe}
                                key={item + index}
                            >
                                {item}
                            </p>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
