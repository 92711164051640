import React from 'react';
import tecImg from '../../../picture/businessSence/rentalManagement.png';

const styles = require('./selfTecDescribe.module.scss');

export default function SelfTechDescribe() {
    const selfTechInfo = {
        describe:
            '易乾科技发展至今已经取得了多项科技认证，研发多种技术架构体系\n我们有专业全面的产品方案矩阵，建立全方位领先优势，是流动人口数字治理服务领域最优价值整体方案缔造者。我们拥有：· "管、云、端"全链路自研技术方案，百万级并发AIoT云平台架构',
        tecImg: tecImg,
        describeList: [
            '国密级芯片+国家唯一权威可信身份认证平台加持',
            '首创流动人口治理AI算法模型',
            '自研软硬件产品稳定成型，经过多年规模市场的检验和沉淀',
            '7*24H专业标准化运维服务体系',
        ],
        bottomSummary:
            '在未来我们将会为您带来更多先进技术支持和更优良的服务，敬请期待~',
    };

    return (
        <div className={styles.mainContainer}>
            <img
                src={selfTechInfo.tecImg}
                className={styles.tecImg}
                alt="tecImg"
            />
            <div className={styles.rightContainer}>
                <p className={styles.describe}>{selfTechInfo.describe}</p>
                <ul>
                    {selfTechInfo.describeList.map((item, index) => {
                        return <li key={item + index}>{item}</li>;
                    })}
                </ul>
                <div className={styles.bottomSummary}>
                    {selfTechInfo.bottomSummary}
                </div>
            </div>
        </div>
    );
}
