import React from 'react';
import partnerLogo from '../../../picture/partner.png';

const styles = require('./partnerComponent.module.scss');

export default function PartnerComponent() {
    const partnerInfo = {
        title: '合作伙伴',
        describe:
            '凭着过硬的技术、高质量的产品和及时完善的服务体系，近年来已成为中国建设银行云南省分行的指定供货商和服务商，成功案例以昆明市为中心覆盖全省，并逐年增加。多年来我公司秉承“诚信为本，实力为先，全心全意为客户服务”的经营理念力争成为业界最具竞争力的行业安防品牌。',
        partnerLogo: partnerLogo,
    };

    return (
        <div className={styles.mainContainer}>
            <p className={styles.title}>{partnerInfo.title}</p>
            <p className={styles.describe}>{partnerInfo.describe}</p>
            <img
                className={styles.partnerLogo}
                src={partnerInfo.partnerLogo}
                alt="partnerLogo"
            />
        </div>
    );
}
