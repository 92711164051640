import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
    smartCommunityInfo,
    populationInfo,
    payInfo,
    parkInfo,
    selfPlatformInfo,
} from '../infoConfig/platformConfig';
import RightImageDisplay from '../productCommonComponent/RightImageDisplay';
import BottomImpactComponent from '../productCommonComponent/BottomImpactComponent';
import BottomDescribeComponent from '../productCommonComponent/BottomDescribeComponent';
import SelfPlatformComponent from '../productCommonComponent/SelfPlatformComponent';

const styles = require('./platformFeatures.module.scss');
export default function PlatformFeatures() {
    // const [platformInfo, setPlatformInfo] = useState(smartCommunityInfo);
    const [platformInfo, setPlatformInfo] = useState(smartCommunityInfo);
    const { productName } = useParams();
    useEffect(() => {
        console.log('productName' + productName);
        productName === 'smart'
            ? setPlatformInfo(smartCommunityInfo)
            : productName === 'population'
            ? setPlatformInfo(populationInfo)
            : productName === 'pay'
            ? setPlatformInfo(payInfo)
            : productName === 'park'
            ? setPlatformInfo(parkInfo)
            : productName === 'selfTec'
            ? setPlatformInfo(selfPlatformInfo)
            : console.log('another');
    }, [productName]);

    return (
        <div className={styles.continer}>
            {platformInfo.title && (
                <div className={styles.mainContainer}>
                    <SelfPlatformComponent selfPlatformInfo={platformInfo} />
                </div>
            )}
            {!platformInfo.title && (
                <div className={styles.mainContainer}>
                    {/*{!platformInfo.title&&}*/}
                    <div className={styles.leftContainer}>
                        <div className={styles.platformContainer}>
                            <img
                                src={platformInfo.platformImg}
                                className={styles.platformImg}
                                alt="platformImg"
                            />
                            <div className={styles.platformRightContainer}>
                                <div className={styles.titleContainer}>
                                    <p className={styles.platformTitle}>
                                        {platformInfo.platformName}
                                    </p>
                                    <p className={styles.englishTitle}>
                                        {platformInfo.platformEnglistName}
                                    </p>
                                </div>
                                <div className={styles.tagContainer}>
                                    {platformInfo.platformTags.map(
                                        (item, index) => {
                                            return (
                                                <div
                                                    className={styles.cube}
                                                    key={(item, index)}
                                                >
                                                    <p>{item}</p>
                                                </div>
                                            );
                                        }
                                    )}
                                </div>
                                <p className={styles.summary}>
                                    {platformInfo.platformSummary}
                                </p>
                            </div>
                        </div>
                        {platformInfo.platformFeaturePoints && (
                            <BottomImpactComponent
                                impactInfo={platformInfo.platformFeaturePoints}
                                featureTitle={platformInfo.platformFeatureTitle}
                            />
                        )}
                        {platformInfo.platformDescribe && (
                            <BottomDescribeComponent
                                describeInfo={platformInfo.platformDescribe}
                            />
                        )}
                    </div>
                    <div className={styles.rightContainer}>
                        <RightImageDisplay
                            imageInfo={platformInfo.platformRightImg}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
