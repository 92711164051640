import React from 'react';
import { useHistory } from 'react-router-dom';
import backImg from '../../picture/businessTalk.png';

const styles = require('./bussinessTalk.module.scss');
export default function BussinessTalk() {
    let history = useHistory();
    function jumpInto(path) {
        history.push(path);
    }
    return (
        <div className={styles.mainContainer}>
            <img src={backImg} alt="bussinessBackImg" />
            <p className={styles.title}>咨询&商务洽谈</p>
            <p className={styles.describe}>
                客服人员12小时在线,随时回复您的疑问,期待您的联系
            </p>
            <button
                onClick={() => {
                    jumpInto('/aboutUs/contact');
                    window.scrollTo(0, 0);
                }}
            >
                联系我们
            </button>
        </div>
    );
}
