import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import communitySecurityImg from '../../../../picture/solutions/communitySecurity/communitySecurity.png';
import securityImgOne from '../../../../picture/solutions/communitySecurity/securityDeiailImgOne.png';
import securityImgTwo from '../../../../picture/solutions/communitySecurity/securityDetailImageTwo.png';
import populationBackImg from '../../../../picture/solutions/floatingPopulation/backImage.png';
import populationImgOne from '../../../../picture/solutions/floatingPopulation/detailImageOne.png';
import populationImgTwo from '../../../../picture/solutions/floatingPopulation/detailImageTwo.png';
import rentalBackImg from '../../../../picture/solutions/rentalMangement/backImg.png';
import rentalImgOne from '../../../../picture/solutions/rentalMangement/rentalImgOne.png';
import rentalImgTwo from '../../../../picture/solutions/rentalMangement/rentalImgTwo.png';
import parkBackImg from '../../../../picture/solutions/smartPark/backImg.png';
import parkImgOne from '../../../../picture/solutions/smartPark/parkImageOne.png';
import parkImageTwo from '../../../../picture/solutions/smartPark/parkImgTwo.png';
const styles = require('./solutions.module.scss');
export default function Solutions() {
    const history = useHistory();
    const jumpTo = (path) => history.push(path);
    const [show, setShow] = useState(0);
    const solutionsMapInfo = [
        {
            changeBarTitle: '智慧社区安防',
            backImage: communitySecurityImg,
            mainTitle: '智慧社区安防解决方案',
            totalDescribe: '提供完整的门禁安保全链路解决方案',
            detailDescribe:
                '"智慧社区安防解决方案"按照"统一规划、统一标准、统一平台、统一管理"的设计思路，通过社区内的视频监控、微卡口、人脸门禁和各类物联感知设备，实现社区数据、事件的全面感知，并充分运用大数据、人工智能、物联网等新技术，建设以大数据智能应用为核心的"智能安防社区系统",形成了公安、综治、街道、物业多方联合的立体化社区防控体系，有效提升了特殊人群、重点关注...',
            detailImageOne: securityImgOne,
            detailImageTwo: securityImgTwo,
            morePath: '/product/smart',
        },
        {
            changeBarTitle: '流动人口数字治理',
            backImage: populationBackImg,
            mainTitle: '流动人口数字治理解决方案',
            totalDescribe: '基于大数据分析的全链路方案',
            detailDescribe:
                '本方案基于“精准、鲜活、可信”的数据特征，运营AI、大数据等技术，提供多主题数据大屏展示和管控服务，支持多端呈现。通过实时掌控辖区内基础常态管理、异常动态管理，提供分析决策支撑，实现“一目了然，实时决策，精准服务”的多维度数字治理服务...',
            detailImageOne: populationImgOne,
            detailImageTwo: populationImgTwo,
            morePath: '/product/population',
        },
        {
            changeBarTitle: '出租房屋智能管理',
            backImage: rentalBackImg,
            mainTitle: '出租房屋智能管理解决方案',
            totalDescribe: '房屋，租户，租客一体化方案',
            detailDescribe:
                '通过实人认证承租人身份，出租房屋流动人口自动申报，一键实施实名管控，合法合规；通过手机即可实现租客管理、房间管理、租约管理、费用管理、安全预警等场景操作;为房屋管理提供软硬件一体化的完整解决方案...',
            detailImageOne: rentalImgOne,
            detailImageTwo: rentalImgTwo,
            morePath: '/product/pay',
        },
        {
            changeBarTitle: '停车智能化',
            backImage: parkBackImg,
            mainTitle: '停车智能化解决方案',
            totalDescribe: '车辆管理，车位导航整合管理',
            detailDescribe:
                '停车智能化建设核心为给车主提供更加安全、舒适、方便、快捷和开放的环境，实现停车场运行的高效化、节能化、环保化，并解决实际业务问题，以多种方式综合推进停车场数字化建设...',
            detailImageOne: parkImgOne,
            detailImageTwo: parkImageTwo,
            morePath: '/product/park',
        },
        {
            changeBarTitle: '更多...',
            backImage: rentalBackImg,
            mainTitle: '出租房屋智能管理解决方案',
            totalDescribe: '房屋，租户，租客一体化方案',
            detailDescribe:
                '通过实人认证承租人身份，出租房屋流动人口自动申报，一键实施实名管控，合法合规；通过手机即可实现租客管理、房间管理、租约管理、费用管理、安全预警等场景操作;为房屋管理提供软硬件一体化的完整解决方案...',
            detailImageOne: rentalImgOne,
            detailImageTwo: rentalImgTwo,
            morePath: '/product/selfTec',
        },
    ];
    return (
        <div className={styles.mainContainer}>
            <p className={styles.firstTitle}>多样化的解决方案</p>
            <div className={styles.upContainer}>
                {solutionsMapInfo.map((item, index) => {
                    return (
                        <div
                            className={styles.barAndImgContainer}
                            key={item + index}
                        >
                            <img
                                src={item.backImage}
                                style={
                                    show === index
                                        ? { display: 'inline' }
                                        : { display: 'none' }
                                }
                                alt="backImage"
                            />
                            <div className={styles.changeBar}>
                                <button
                                    className={styles.changeTitle}
                                    onClick={() => {
                                        setShow(index);
                                    }}
                                    style={
                                        show === 0 && show === index
                                            ? {
                                                  color: '#00a4ff',
                                              }
                                            : {}
                                    }
                                >
                                    {item.changeBarTitle}
                                </button>
                                <div
                                    className={styles.line}
                                    style={
                                        show === 0 && show === index
                                            ? {
                                                  backgroundColor: '#13619e',
                                              }
                                            : {}
                                    }
                                ></div>
                            </div>
                        </div>
                    );
                })}
            </div>
            {solutionsMapInfo.map((item, index) => {
                return (
                    show === index && (
                        <div className={styles.describeContainer} key={index}>
                            <div className={styles.leftContainer}>
                                <p>{item.mainTitle}</p>
                                <p>{item.totalDescribe}</p>
                                <p>{item.detailDescribe}</p>
                                <button
                                    onClick={() => {
                                        jumpTo(item.morePath);
                                        window.scrollTo(0, 0);
                                    }}
                                >
                                    了解更多
                                </button>
                            </div>
                            <div className={styles.rightContainer}>
                                <img
                                    src={item.detailImageOne}
                                    className={styles.detailImage}
                                    alt="detailImgOne"
                                />
                                <img
                                    src={item.detailImageTwo}
                                    className={styles.detailImage}
                                    alt="detailImgTwo"
                                />
                            </div>
                        </div>
                    )
                );
            })}
        </div>
    );
}
