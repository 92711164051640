import React from 'react';
import bacImg from '../../../picture/solutions/rentalMangement/backImg.png';

const styles = require('./topView.module.scss');
export default function TopView() {
    const topViewInfo = {
        title: '产品体系',
        bacImg: bacImg,
    };
    return (
        <div className={styles.mainContainer}>
            <img src={topViewInfo.bacImg} alt="backImg" />
            <p>{topViewInfo.title}</p>
        </div>
    );
}
