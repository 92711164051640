import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// import registerRequireContextHook from 'babel-plugin-require-context-hook/register';
import {
    smartSencesInfo,
    populationSencesInfo,
    rentalSencesInfo,
    smartParkSencesInfo,
    selfTecSencesInfo,
} from './useSencesConfig';

const styles = require('./useSences.module.scss');
const sencesTitle = {
    firstTitle: '适用场景',
    secondTitle: '全方位提高社区安防级别，实时监控',
};
export default function UseSences() {
    // registerRequireContextHook();
    const { name } = useParams();
    const [sencesInfo, setInfo] = useState(smartSencesInfo);
    useEffect(() => {
        console.log(name);
    }, [name]);
    useEffect(() => {
        name === 'smart'
            ? setInfo(smartSencesInfo)
            : name === 'populations'
            ? setInfo(populationSencesInfo)
            : name === 'rental'
            ? setInfo(rentalSencesInfo)
            : name === 'park'
            ? setInfo(smartParkSencesInfo)
            : setInfo(selfTecSencesInfo);
    }, [name]);
    return (
        <div
            className={
                name === 'selfTec'
                    ? styles.hideMainContainer
                    : styles.mainContainer
            }
        >
            <p>{sencesTitle.firstTitle}</p>
            <p>{sencesTitle.secondTitle}</p>
            <div className={styles.mapContainer}>
                {sencesInfo.map((item, index) => {
                    return (
                        <div
                            className={styles.cubeContainer}
                            key={item + index}
                        >
                            <img
                                className={styles.bacImg}
                                src={item.senceBacImg}
                                alt={item.senceName}
                            />
                            <div className={styles.senceName}>
                                <p>{item.senceName}</p>
                            </div>
                        </div>
                    );
                })}
            </div>
            {/*<div>this is use sences</div>*/}
        </div>
    );
}
