import bigData from '../../../picture/businessSence/bigData.png';
import pay from '../../../picture/businessSence/pay.png';
import parkManagement from '../../../picture/businessSence/parkManagement.png';
import smartCity from '../../../picture/businessSence/smartCity.png';

export const smartSencesInfo = [
    { senceName: '商场', senceBacImg: bigData },
    { senceName: '别墅', senceBacImg: pay },
    { senceName: '公寓', senceBacImg: parkManagement },
    { senceName: '学校', senceBacImg: smartCity },
    { senceName: '公司', senceBacImg: bigData },
    { senceName: '银行', senceBacImg: pay },
    { senceName: '街道', senceBacImg: parkManagement },
    { senceName: '景区', senceBacImg: smartCity },
];
export const populationSencesInfo = [
    { senceName: 'TMALL', senceBacImg: bigData },
    { senceName: '别墅', senceBacImg: pay },
    { senceName: '公寓', senceBacImg: parkManagement },
    { senceName: '学校', senceBacImg: smartCity },
    { senceName: '公司', senceBacImg: bigData },
    { senceName: '银行', senceBacImg: pay },
    { senceName: '街道', senceBacImg: parkManagement },
    { senceName: '景区', senceBacImg: smartCity },
];
export const rentalSencesInfo = [
    { senceName: '商场', senceBacImg: bigData },
    { senceName: '别墅', senceBacImg: pay },
    { senceName: '公寓', senceBacImg: parkManagement },
    { senceName: '学校', senceBacImg: smartCity },
    { senceName: '公司', senceBacImg: bigData },
    { senceName: '银行', senceBacImg: pay },
    { senceName: '街道', senceBacImg: parkManagement },
    { senceName: '景区', senceBacImg: smartCity },
];
export const smartParkSencesInfo = [
    { senceName: '商场', senceBacImg: bigData },
    { senceName: '别墅', senceBacImg: pay },
    { senceName: '公寓', senceBacImg: parkManagement },
    { senceName: '学校', senceBacImg: smartCity },
    { senceName: '公司', senceBacImg: bigData },
    { senceName: '银行', senceBacImg: pay },
    { senceName: '街道', senceBacImg: parkManagement },
    { senceName: '景区', senceBacImg: smartCity },
];
export const selfTecSencesInfo = [
    { senceName: '商场', senceBacImg: bigData },
    { senceName: '别墅', senceBacImg: pay },
    { senceName: '公寓', senceBacImg: parkManagement },
    { senceName: '学校', senceBacImg: smartCity },
    { senceName: '公司', senceBacImg: bigData },
    { senceName: '银行', senceBacImg: pay },
    { senceName: '街道', senceBacImg: parkManagement },
    { senceName: '景区', senceBacImg: smartCity },
];
