import React, { useState } from 'react';

// import { Container } from '../../repository/repository';
import { createFromIconfontCN } from '@ant-design/icons';

import smartCity from '../../../../picture/businessSence/smartCity.png';
import bigData from '../../../../picture/businessSence/bigData.png';
import parkManagement from '../../../../picture/businessSence/parkManagement.png';
import pay from '../../../../picture/businessSence/pay.png';
import rentalMangement from '../../../../picture/businessSence/rentalManagement.png';
import communitySecurity from '../../../../picture/businessSence/communitySecurity.png';
import shape from '../../../../picture/businessSence/shape.png';
const MyIcon = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_2363312_qxdp994ufjl.js',
});
const styles = require('./businessSence.module.scss');
export default function BusinessSence() {
    const [show, setShow] = useState(false);
    // const repository = Container.useContainer();
    const mapInfo = [
        {
            title: '智慧城市',
            englishTitle: 'Community security',
            backgroundImage: smartCity,
            icon: 'iconchengshi',
            describe:
                '以应用计算机、互联网、3S、多媒体等技术将城市地\n理信息和城市其他信息相结合，数字化并形成城市虚\n拟空间',
        },
        {
            title: '社区安防',
            englishTitle: 'Community security',
            backgroundImage: communitySecurity,
            icon: 'iconanfang',
            describe:
                '通过社区内的视频监控、微卡口、人脸门禁以及各类型感\n知设备，实现社区数据、事件的全面感知，并运用大数据\n人工智能、等新技术，建设“智能社区安防平台”',
        },
        {
            title: '停车场管控',
            englishTitle: 'Community security',
            backgroundImage: parkManagement,
            icon: 'icontingche',
            describe:
                '安装车辆感应设备收集数据，建立一体化的停车场后台管\n理系统，实现停车自动导航、在线支付停车费等服务，并\n铺设自动化泊车管理系统，合理疏导车流。',
        },
        {
            title: '线上支付',
            englishTitle: 'Community security',
            backgroundImage: pay,
            icon: 'iconxianshangzhifu',
            describe:
                '利用自身的技术与服务集成能力，将一个或一个以上的银\n行、非银机构或清算组织的支付服务，整合到一起，为商\n户提供 快捷便利的支付体验',
        },
        {
            title: '出租房管理',
            englishTitle: 'Community security',
            backgroundImage: rentalMangement,
            icon: 'iconfangwu',
            describe:
                '使用实名制门禁系统，并采用硬件设备+云平台管理+ APP\n或微信公众号架构手机门禁身份证平台，与公安管理系统无\n缝对接，为出租屋安全保驾护航',
        },
        {
            title: '大数据治理',
            englishTitle: 'Community security',
            backgroundImage: bigData,
            icon: 'icondashuju',
            describe:
                '通过实时采集、治理、存储、查询、展示数据，搭载数\n据智能引擎，高效积累数据资产，赋能业务应用场景，助\n力企业构建扎实的数据根基，实现数字化经营。',
        },
    ];
    const connectInfo = {
        phone: '电话:13666547899',
        qq: 'QQ:897897898（晶晶）',
        weChat: '微信:798598489498（刘冰）',
    };
    return (
        <div
            className={
                // repository.topBarIndex === 0
                //     ? styles.container
                //     : styles.hideContainer
                styles.container
            }
        >
            <p>业务应用场景</p>
            <div className={styles.mapContainer}>
                {mapInfo.map((item, index) => {
                    return (
                        <div className={styles.cubeContainer} key={index}>
                            <img
                                src={item.backgroundImage}
                                alt="bussinessBackground"
                            />
                            <MyIcon className={styles.icon} type={item.icon} />
                            <div className={styles.titleContainer}>
                                <div className={styles.titleLine}></div>
                                <p className={styles.title}>{item.title}</p>
                                <div className={styles.titleLine}></div>
                            </div>
                            <p className={styles.englishTitle}>
                                {item.englishTitle}
                            </p>
                            <p className={styles.describe}>{item.describe}</p>
                        </div>
                    );
                })}
            </div>
            <div className={styles.connectBox}>
                {show && (
                    <div className={styles.connectInfo}>
                        <p>你好,商务请联系</p>
                        <div className={styles.connectWay}>
                            <MyIcon
                                type="icondianhua"
                                className={styles.phoneIcon}
                            />
                            <p>{connectInfo.phone}</p>
                        </div>
                        <div className={styles.connectWay}>
                            <MyIcon type="iconqq" className={styles.qqIcon} />
                            <p>{connectInfo.qq}</p>
                        </div>
                        <div className={styles.connectWay}>
                            <MyIcon
                                type="icontubiaozhizuo-"
                                className={styles.weChatIcon}
                            />
                            <p>{connectInfo.weChat}</p>
                        </div>
                    </div>
                )}
                <div>
                    <div
                        className={styles.connectContainer}
                        onMouseEnter={() => {
                            setShow(true);
                        }}
                        onMouseLeave={() => {
                            setShow(false);
                        }}
                    >
                        <MyIcon
                            className={styles.connectIcon}
                            type={show ? 'iconxinxi-copy' : 'iconxinxi'}
                        />
                        <p>联系我们</p>
                    </div>
                    <div className={styles.upArrowContainer}>
                        <MyIcon
                            className={styles.upArrow}
                            type="iconxiangshangjiantou"
                        />
                    </div>
                </div>
            </div>
            <img src={shape} className={styles.shape} alt="shapeImg" />
        </div>
    );
}
