import React, { useState } from 'react';
// import { Container } from '../../repository/repository';
import { Button } from 'antd';
import leftQuote from '../../../../picture/leftQuote.png';
import rightQuote from '../../../../picture/rightQuote.png';
const styles = require('./introduction.module.scss');

export default function Introduction() {
    const [show, setShow] = useState(0);
    // const repository = Container.useContainer();
    const introduceMessage = [
        {
            key: '公司介绍',
            describe:
                '云南易乾科技是一家制作智能化系统解决方案和设备研发、设计、生产、销售、安装、维护为一体的科技型公司。\n致力于门禁、道闸、监控、弱电智能化等安防产品，为各大停车场、社区、商超、写字楼等提供专业的技术方案以及优质产品\n公司的经营理念是“诚信为本，实力为先，全心全意为客户服务”',
        },
        {
            key: '主营业务',
            describe: '123',
        },
        {
            key: '科技创新',
            describe: '223',
        },
    ];
    return (
        <div
            className={
                // repository.topBarIndex === 0
                //     ? styles.container
                //     : styles.hideContainer
                styles.container
            }
        >
            {introduceMessage.map((item, index) => {
                return (
                    <div className={styles.mapContainer} key={index}>
                        {index === show && (
                            <div className={styles.articleContainer}>
                                <img src={leftQuote} alt="leftQuoteImg" />
                                <p>{item.describe}</p>
                                <img src={rightQuote} alt="rightQuoteImg" />
                            </div>
                        )}
                    </div>
                );
            })}
            <div className={styles.buttonContainer}>
                {introduceMessage.map((item, index) => {
                    return (
                        <Button
                            key={index}
                            type="default"
                            onClick={() => {
                                setShow(index);
                            }}
                        >
                            {item.key}
                        </Button>
                    );
                })}
            </div>
        </div>
    );
}
