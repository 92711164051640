import React from 'react';
import { createFromIconfontCN } from '@ant-design/icons';
import addressImg from '../../../picture/solutions/floatingPopulation/detailImageTwo.png';

const styles = require('./contactComponent.module.scss');
export default function ContactComponent() {
    const MyIcon = createFromIconfontCN({
        scriptUrl: '//at.alicdn.com/t/font_2363312_d7srnqrfyzg.js',
    });
    const contactInfo = {
        contactTitle: '联系方式',
        contactDescribe:
            '您可以通过以下方式快速的联系我们，我的服务人员会在最快的时间内给您反馈',
        contactMember: [
            {
                contactIcon: 'icondianhua',
                contactWay: '咨询电话',
                contactDetail: '13666547899',
            },
            {
                contactIcon: 'iconqq',
                contactWay: '客服QQ',
                contactDetail: '897897898（晶晶）',
            },
            {
                contactIcon: 'icontubiaozhizuo-',
                contactWay: '微信',
                contactDetail: '798598489498（刘冰）',
            },
        ],
        companyName: '云南易乾科技有限公司',
        companyAddress:
            '昆明市五华区王筇路与科普路交叉口中铁云时代广场2栋802室',
        addressIcon: 'iconditu',
        addressImg: addressImg,
    };
    return (
        <div className={styles.mainContainer}>
            <p className={styles.contactTitle}>{contactInfo.contactTitle}</p>
            <p className={styles.contactDescribe}>
                {contactInfo.contactDescribe}
            </p>
            <div className={styles.contactMemberContainer}>
                {contactInfo.contactMember.map((item, index) => {
                    return (
                        <div
                            className={styles.cubeContainer}
                            key={item + index}
                        >
                            <MyIcon
                                type={item.contactIcon}
                                className={styles.cubeIcon}
                            />
                            <div className={styles.rightContainer}>
                                <p className={styles.contactWay}>
                                    {item.contactWay}
                                </p>
                                <p className={styles.contactDetail}>
                                    {item.contactDetail}
                                </p>
                            </div>
                        </div>
                    );
                })}
            </div>
            <p className={styles.companyName}>{contactInfo.companyName}</p>
            <div className={styles.addressContainer}>
                <MyIcon
                    type={contactInfo.addressIcon}
                    className={styles.addressIcon}
                />
                <p className={styles.companyAddress}>
                    {contactInfo.companyAddress}
                </p>
            </div>
            <img
                src={contactInfo.addressImg}
                alt={contactInfo.companyAddress}
                className={styles.addressImg}
            />
        </div>
    );
}
