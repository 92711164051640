import React from 'react';
import { Link } from 'react-router-dom';

import teamIcon from '../../picture/bottom/team.png';
import measureIcon from '../../picture/bottom/measure.png';
import customerIcon from '../../picture/bottom/customer.png';
import partnerIcon from '../../picture/bottom/partner.png';
import bottomLogo from '../../picture/bottom/bottomLogo.png';
import qrCode from '../../picture/qrCode.png';
import yiqianQrCode from '../../picture/yiqianQrCode.png';

const styles = require('./bottomComponent.module.scss');
export default function BottomComponent() {
    const bottomInfo = [
        { tagTitle: '易乾开放平台', tagHerf: '/product/selfTec' },
        { tagTitle: '易乾智慧停车系统', tagHerf: '/product/park' },
        { tagTitle: '易乾智慧支付平台', tagHerf: '/product/pay' },
        { tagTitle: '友情链接', tagHerf: '' },
    ];
    const iconInfo = [
        {
            title: '专业技术人员团队',
            icon: teamIcon,
        },
        {
            title: '量身定制的解决方案',
            icon: measureIcon,
        },
        {
            title: '客户为本的服务理念',
            icon: customerIcon,
        },
        {
            title: '众多的合作伙伴',
            icon: partnerIcon,
        },
    ];
    const linkInfo = [
        {
            title: '解决方案',
            members: [
                {
                    tagText: '智慧社区安防系统解决方案',
                    tagHref: '/solutions/smart',
                },
                {
                    tagText: '流动人口数字治理服务解决方案',
                    tagHref: '/solutions/populations',
                },
                {
                    tagText: '出租房屋智能化管理解决方案',
                    tagHref: '/solutions/rental',
                },
                {
                    tagText: '停车智能化解决方案',
                    tagHref: '/solutions/park',
                },
                { tagText: '更多...', tagHref: '/solutions/selfTec' },
            ],
        },
        {
            title: '产品',
            members: [
                { tagText: '智慧小区', tagHref: '/product/smart' },
                {
                    tagText: '流动人口管理统计',
                    tagHref: '/product/population',
                },
                { tagText: '聚合支付平台', tagHref: '/product/pay' },
                { tagText: '智能停车平台', tagHref: '/product/park' },
                { tagText: '自研开放平台', tagHref: '/product/selfTec' },
            ],
        },
        {
            title: '关于我们',
            members: [
                { tagText: '公司介绍', tagHref: '/aboutUs/company' },
                { tagText: '主营业务', tagHref: '/aboutUs/bussiness' },
                { tagText: '科技创新', tagHref: '/aboutUs/innovation' },
                { tagText: '联系我们', tagHref: '/aboutUs/contact' },
                { tagText: '合作伙伴', tagHref: '/aboutUs/partner' },
            ],
        },
        {
            title: '联系方式',
            members: [
                { tagText: '电话咨询：0871-682555180', tagHref: '' },
                {
                    // tagText: '技术支持QQ：shinescy@qq.com（工作日9：00-18：00）',
                    tagText: '公众号: YQSmartCloud',
                    tagHref: '',
                },
                {
                    tagText: '邮箱：shinescy@qq.com\n（请备注姓名和缘由）',
                    tagHref: '',
                },
            ],
        },
    ];
    return (
        <div className={styles.mainContainer}>
            <div className={styles.iconMapContainer}>
                {iconInfo.map((item, index) => {
                    return (
                        <div className={styles.cubeContainer} key={index}>
                            <div className={styles.iconContainer}>
                                <img src={item.icon} alt="iconImg" />
                            </div>
                            <p>{item.title}</p>
                        </div>
                    );
                })}
            </div>
            <div className={styles.midContainer}>
                {linkInfo.map((item, index) => {
                    return (
                        <div
                            className={styles.infoMapContainer}
                            key={item + index}
                        >
                            <p className={styles.infoTitle}>{item.title}</p>
                            <div
                                className={
                                    item.title === '联系方式'
                                        ? styles.connectContainer
                                        : styles.linkMapContainer
                                }
                            >
                                {item.members.map((item, index) => {
                                    return (
                                        <Link
                                            key={item + index}
                                            to={item.tagHref}
                                            onClick={() => {
                                                window.scrollTo(0, 0);
                                            }}
                                        >
                                            {item.tagText}
                                        </Link>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
                <div className={styles.copyRight}>
                    <p>
                        Copyright © YIQIANKEJI .All
                        <br />
                        Rights Teserved.易乾科技 版权所有
                    </p>
                    <button>业务咨询：0871-682555180</button>
                </div>
            </div>
            <div className={styles.bottomContainer}>
                <img
                    src={bottomLogo}
                    className={styles.bottomLogo}
                    alt="bottomLogo"
                />
                <div className={styles.bottomRightContainer}>
                    <div className={styles.lettersContainer}>
                        <div className={styles.bottomLinkContainer}>
                            {bottomInfo.map((item, index) => {
                                return (
                                    <Link
                                        to={item.tagHerf}
                                        // href={item.tagHerf}
                                        key={item + index}
                                        onClick={() => {
                                            window.scrollTo(0, 0);
                                        }}
                                    >
                                        {item.tagTitle}
                                    </Link>
                                );
                            })}
                        </div>
                        <p>
                            云南易乾科技有限公司 |   
                            <a href="https://beian.miit.gov.cn/#/Integrated/index">  滇ICP备17004479号</a>
                        </p>
                    </div>
                    <img
                        src={yiqianQrCode}
                        className={styles.qrCode}
                        alt="yiqianQrcode"
                    />
                </div>
            </div>
        </div>
    );
}
