import React, { useState } from 'react';
// import { Container } from '../../repository/repository';
import styles from './scrollImage.module.scss';
import { Button } from 'antd';

export default function ScrollImage() {
    const [scrollNumber, setScrollNumber] = useState(0);
    // const repository = Container.useContainer();
    // const { topBarIndex } = repository;
    // const repository = Container.useContainer();
    // useEffect(() => {
    //     setTimeout(() => {
    //         setScrollNumber(scrollNumber + 1);
    //     }, 5000);
    // }, [scrollNumber]);
    // useEffect(() => {
    //     if (scrollNumber === 3) {
    //         setTimeout(() => {
    //             setScrollNumber(0);
    //         }, 5000);
    //     }
    //     console.log(scrollNumber);
    // }, [scrollNumber]);
    return (
        <div className={styles.container}>
            {/*<div className={styles.holder}></div>*/}
            <div className={styles.mainContainer}>
                {scrollNumber === 0 ? (
                    <div className={styles.articleContainer}>
                        <p>易乾科技</p>
                        <p>专业大数据智慧平台建设</p>
                        <p>
                            提供全链路的智慧平台解决方案，从软件到硬件全部一次搞定
                            更有专业人员定期维护和值守，军工级品质保证
                        </p>
                        <Button type="primary">点击了解详情</Button>
                    </div>
                ) : scrollNumber === 1 ? (
                    <div>1 bar</div>
                ) : scrollNumber === 2 ? (
                    <div>2 bar</div>
                ) : scrollNumber === 3 ? (
                    <div>3 bar</div>
                ) : (
                    <div></div>
                )}
                <div className={styles.showBar}>
                    <div
                        onMouseEnter={() => {
                            setScrollNumber(0);
                        }}
                    ></div>
                    <div
                        onMouseEnter={() => {
                            setScrollNumber(1);
                        }}
                    ></div>
                    <div
                        onMouseEnter={() => {
                            setScrollNumber(2);
                        }}
                    ></div>
                    <div
                        onMouseEnter={() => {
                            setScrollNumber(3);
                        }}
                    ></div>
                </div>
            </div>
        </div>
    );
}
