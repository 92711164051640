import React from 'react';
import logo from '../../../picture/LOGO.png';

const styles = require('./companyComponent.module.scss');
export default function CompanyComponent() {
    const companyInfo = {
        imgOne: logo,
        describeMember: [
            '云南易乾科技有限公司 成立于2009年，是一家制作智能化系统解决方案和设备的专业研发、设计、\n生产、销售、安装、维护为一体的科技型公司。',
            '公司多年来致力于门禁、道闸、监控、弱电智能化等安防产品的设计、安装、维护，以该类产品\n的核心组件和成套产品为输出，为各大停车场、社区、商超、写字楼等提供专业的技术方案以及优质的产品。',
        ],
        beliefTitle: '经营理念',
        beliefSummary: '诚信为本，实力为先，全心全意为客户服务',
        beliefDescribe:
            '一切以诚信为基本准则，技术力量为前提要求，以用户价值和诉求为依据，全心全意为用户服务',
    };
    return (
        <div className={styles.mainContainer}>
            <div className={styles.logoContainer}>
                <img src={companyInfo.imgOne} alt="imgOne" />
                <div className={styles.separation}></div>
            </div>
            <div className={styles.describeContainer}>
                {companyInfo.describeMember.map((item, index) => {
                    return (
                        <p className={styles.describeMember} key={item + index}>
                            {item}
                        </p>
                    );
                })}
            </div>
            <div className={styles.bottomContainer}>
                <p className={styles.beliefTitle}>{companyInfo.beliefTitle}</p>
                <div className={styles.beliefSeparation}></div>
                <div className={styles.rightContainer}>
                    <p className={styles.beliefSummary}>
                        {companyInfo.beliefSummary}
                    </p>
                    <p className={styles.beliefDescribe}>
                        {companyInfo.beliefDescribe}
                    </p>
                </div>
            </div>
        </div>
    );
}
