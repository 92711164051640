import React, { useEffect, useState } from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import TopBar from '../commonComponent/TopBar';
import BussinessTalk from '../commonComponent/BussinessTalk';
import BottomComponent from '../commonComponent/BottomComponent';
import ChangeSences from '../solutionsPage/pageComponent/ChangeSences';

import SmartSolutionDescribe from '../solutionsPage/pageComponent/SmartSolutionDescribe';
import UseSences from '../solutionsPage/solutionsCommonComponent/UseSences';
import PopulationDescribe from '../solutionsPage/pageComponent/PopulationDescribe';
import RentalDescribe from '../solutionsPage/pageComponent/RentalDescribe';
import SmartParkDescribe from '../solutionsPage/pageComponent/SmartParkDescribe';

import SelfTechDescribe from '../solutionsPage/pageComponent/SelfTecDescribe';

const styles = require('./solutionsPage.module.scss');
export default function SolutionsPage() {
    const { path, url, params } = useRouteMatch();
    const [status, setStatus] = useState(0);
    useEffect(() => {
        console.log('firstPage path is : ' + path);
        console.log('firstPage url is : ' + url);
        console.log('firstPage params is: ');
        console.log(params);
    }, [params, path, url]);

    useEffect(() => {
        console.log('path is :' + path);
    }, [path]);
    useEffect(() => {
        const router = url.split('/');
        router[1] === 'solutions'
            ? sessionStorage.setItem('topBarStatus', 1)
            : console.log('solutions router wrong');
        setStatus(status + 1);
        router[2] === 'smart'
            ? sessionStorage.setItem('changesStatus', 0)
            : router[2] === 'populations'
            ? sessionStorage.setItem('changesStatus', 1)
            : router[2] === 'rental'
            ? sessionStorage.setItem('changesStatus', 2)
            : router[2] === 'park'
            ? sessionStorage.setItem('changesStatus', 3)
            : router[2] === 'selfTec'
            ? sessionStorage.setItem('changesStatus', 4)
            : console.log('solutions router params wrong');
    }, [url]);
    return (
        <div className={styles.container}>
            <TopBar />
            <ChangeSences />
            <Route path="/solutions/smart" component={SmartSolutionDescribe} />
            <Route
                path="/solutions/populations"
                component={PopulationDescribe}
            />
            <Route path="/solutions/rental" component={RentalDescribe} />
            <Route path="/solutions/park" component={SmartParkDescribe} />
            <Route path="/solutions/selfTec" component={SelfTechDescribe} />
            <UseSences />
            <BussinessTalk />
            <BottomComponent />
        </div>
    );
}
