import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from '../../repository/repository';
import { Button } from 'antd';
import styles from './topBar.module.scss';
import logo from '../../picture/LOGO.png';
export default function TopBar() {
    const repository = Container.useContainer();
    const barMapInfo = [
        { buttonTitle: '首页', path: '/' },
        { buttonTitle: '解决方案', path: '/solutions/smart' },
        { buttonTitle: '产品体系', path: '/product/smart' },
        { buttonTitle: '关于我们', path: '/aboutUs/company' },
    ];
    let history = useHistory();
    const jumpTo = (path) => history.push(path);
    const status = Number(sessionStorage.getItem('topBarStatus'));

    return (
        <div className={styles.topBarContainer}>
            <div className={styles.innerContainer}>
                <div className={styles.leftContainer}>
                    <img
                        src={logo}
                        className={styles.logoImage}
                        alt="易乾科技"
                    />
                    <div className={styles.jumper}>
                        {barMapInfo.map((item, index) => {
                            return (
                                <div
                                    className={styles.mapContainer}
                                    key={item + index}
                                >
                                    <button
                                        onClick={() => {
                                            repository.setTopBarIndex(index);
                                            jumpTo(item.path);
                                            window.scrollTo(0, 0);
                                            sessionStorage.setItem(
                                                'topBarStatus',
                                                index
                                            );
                                        }}
                                    >
                                        {item.buttonTitle}
                                    </button>
                                    <div
                                        className={
                                            Number(
                                                sessionStorage.getItem(
                                                    'topBarStatus'
                                                )
                                            ) === index
                                                ? styles.showLine
                                                : styles.line
                                        }
                                    ></div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className={styles.rightContainer}>
                    <p>“诚信为本，实力为先，全心全意为客户服务”</p>
                    <Button
                        type="primary"
                        onClick={() => jumpTo('/aboutUs/Contact')}
                    >
                        联系我们
                    </Button>
                </div>
            </div>
        </div>
    );
}
