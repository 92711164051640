import React, { useState, useEffect } from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import TopView from './aboutUsCommonComponent/TopView';
import SwitchBar from '../commonComponent/SwitchBar';
import BussinessTalk from '../commonComponent/BussinessTalk';
import BottomComponent from '../commonComponent/BottomComponent';
import CompanyComponent from './pageComponent/CompanyComponent';
import BussinessComponent from './pageComponent/BussinessComponent';
import InnovationComponent from './pageComponent/InnovationComponent';
import ContactComponent from './pageComponent/ContactComponent';
import PartnerComponent from './pageComponent/PartnerComponent';
import { aboutUsSwitchBarInfo } from './infoConfig/aboutUsSwitchBarConfig';
import TopBar from '../commonComponent/TopBar';
const styles = require('./aboutUsPage.module.scss');
export default function AboutUsPage() {
    const { path, url, params } = useRouteMatch();
    const [status, setStatus] = useState(0);
    useEffect(() => {}, [params, path, url]);
    useEffect(() => {
        console.log('path is :' + path);
    }, [path]);

    useEffect(() => {
        const router = url.split('/');
        router[1] === 'aboutUs'
            ? sessionStorage.setItem('topBarStatus', 3)
            : console.log('aboutUs router wrong');
        setStatus(status + 1);
        router[2] === 'company'
            ? sessionStorage.setItem('switchBar', 0)
            : router[2] === 'bussiness'
            ? sessionStorage.setItem('switchBar', 1)
            : router[2] === 'innovation'
            ? sessionStorage.setItem('switchBar', 2)
            : router[2] === 'contact'
            ? sessionStorage.setItem('switchBar', 3)
            : router[2] === 'partner'
            ? sessionStorage.setItem('switchBar', 4)
            : console.log('product router params wrong');
    }, [url]);
    return (
        <div className={styles.mainContainer}>
            <TopBar />
            <TopView />
            <SwitchBar switchBarInfo={aboutUsSwitchBarInfo} />
            <Route path={`/aboutUs/company`} component={CompanyComponent} />
            <Route path={`/aboutUS/bussiness`} component={BussinessComponent} />
            <Route
                path={`/aboutUs/innovation`}
                component={InnovationComponent}
            />
            <Route path={`/aboutUs/contact`} component={ContactComponent} />
            <Route path={`/aboutUS/partner`} component={PartnerComponent} />

            <BussinessTalk />
            <BottomComponent />
        </div>
    );
}
