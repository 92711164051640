import React from 'react';

import imgOne from '../../../picture/businessSence/pay.png';
import imgTwo from '../../../picture/businessSence/smartCity.png';
const styles = require('./populationDescribe.module.scss');
const populationDescribeInfo = {
    summaryTitle: '方案概述',
    summaryDescribe:
        '创新打造数字“新基建”模式，引领社会治理数字化升级基于“精准、鲜活、可信”的数据特征，运营AI、大数据等技术，提供多主题数据大屏展示和管控服务，支持多端呈现。通过实时掌控辖区内基础常态管理、异常动态管理，提供分析决策支撑，实现“一目了然，实时决策，精准服务”的多维度数字治理服务。',
    summaryImg: [imgOne, imgTwo],
    innovationTitle: '方案创新',
    innovationDescribe: [
        '1个平台，2个创新模式，3个社会治理数字化升级城市的流动人口数量大、来源杂、层级多，基层治理压力大，综合治理资源消耗大，在社会治理现代化要求下，亟需“升级流动人口权益、促进服务管理效能、提升综合治理能力”。',
        '我们创新实践基于出租房屋智能门锁等物联终端，“投、建、运”一体的政企合作“数字新基建”模式，“政府主导、企业主投、基层共治”的多方共建，运用AI、大数据、物联网和云计算等先进技术，摸清真实“底数”，以锁管房，以房管人，实现人、物、空间的数字化管理，通过“民生服务+平安综治”数字化双轮驱动，完成主管政府机构、企业、租户等全链路流动人口智能化管理服务。',
        '真正以“一个平台，两个创新模式，三个数字化升级”的顶层设计，构建全域流动人口数字化治理体系，提升城市数字化治理能力和治理水平。',
    ],
};
export default function PopulationDescribe() {
    return (
        <div className={styles.mainContainer}>
            <p className={styles.summaryTitle}>
                {populationDescribeInfo.summaryTitle}
            </p>
            <p className={styles.summaryDescribe}>
                {populationDescribeInfo.summaryDescribe}
            </p>
            <div className={styles.mapContainer}>
                {populationDescribeInfo.summaryImg.map((item, index) => {
                    return (
                        <img
                            className={styles.summaryImg}
                            src={item}
                            alt={`summaryImg${index}`}
                            key={item + index}
                        />
                    );
                })}
            </div>
            <p className={styles.innovationTitle}>
                {populationDescribeInfo.innovationTitle}
            </p>
            {populationDescribeInfo.innovationDescribe.map((item, index) => {
                return (
                    <p className={styles.innovationDescribe} key={item + index}>
                        {item}
                    </p>
                );
            })}
        </div>
    );
}
