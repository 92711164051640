import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
// import { useParams } from 'react-router-dom';

const styles = require('./switchBar.module.scss');
export default function SwitchBar({ switchBarInfo }) {
    const st = Number(sessionStorage.getItem('switchBar'));
    // const { productName } = useParams();
    const [status, setStatus] = useState(0);
    useEffect(() => {
        setStatus(() => sessionStorage.getItem('switchBar'));
        return () => {
            sessionStorage.setItem('switchBar', 0);
            console.log('umount');
        };
    }, []);
    useEffect(() => {
        console.log('status' + status);
    }, [status]);
    const history = useHistory();
    const jumpTo = (path) => history.push(path);

    return (
        <div className={styles.mainContainer}>
            <div className={styles.midContainer}>
                {switchBarInfo.map((item, index) => {
                    return (
                        <div
                            className={styles.switchBlockContainer}
                            key={item + index}
                        >
                            <button
                                onClick={() => {
                                    sessionStorage.setItem('switchBar', index);
                                    jumpTo(item.path);
                                }}
                                className={styles.title}
                            >
                                {item.title}
                            </button>
                            <div
                                className={
                                    st === index ? styles.showLine : styles.line
                                }
                            ></div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
