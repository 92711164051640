import React from 'react';
import styles from './firstPage.module.scss';
import './antd.css';
import ScrollImage from './pageComponent/firstPageComponent/ScrollImage';
import Introduction from './pageComponent/firstPageComponent/Introduction';
import Advances from './pageComponent/firstPageComponent/Advances';
import BusinessSence from './pageComponent/firstPageComponent/BusinessSence';
import Solutions from './pageComponent/firstPageComponent/Solutions';
import Partner from './pageComponent/firstPageComponent/Partner';
import BussinessTalk from '../commonComponent/BussinessTalk';
import TopBar from '../commonComponent/TopBar';

import BottomComponent from '../commonComponent/BottomComponent';
function FirstPage() {
    return (
        <div className={styles.container}>
            <TopBar />
            <ScrollImage />
            <Introduction />
            <Advances />
            <BusinessSence />
            <Solutions />
            <Partner />
            <BussinessTalk />
            <BottomComponent />
        </div>
    );
}

export default FirstPage;
