import React from 'react';

const styles = require('./bottomImpactComponent.module.scss');
export default function BottomImpactComponent({ impactInfo, featureTitle }) {
    return (
        <div className={styles.impactComponentContainer}>
            <p className={styles.featureTitle}>{featureTitle}</p>
            {impactInfo.map((item, index) => {
                return (
                    <div className={styles.itemContainer} key={index + item}>
                        <p className={styles.impactNumber}>{item.number}</p>
                        <p className={styles.rightArticle}>
                            {item.title && (
                                <span className={styles.blueTitle}>
                                    {item.title}
                                </span>
                            )}
                            {item.describe}
                        </p>
                    </div>
                );
            })}
        </div>
    );
}
