import React from 'react';

const styles = require('./bottomDescribeComponent.module.scss');

export default function BottomDescribeComponent({ describeInfo }) {
    return (
        <div className={styles.mainContainer}>
            {describeInfo.map((item, index) => {
                return (
                    <div
                        className={styles.describeContainer}
                        key={index + item}
                    >
                        <p className={styles.describeMember}>{item}</p>
                    </div>
                );
            })}
        </div>
    );
}
