import React, { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import BackImg from '../../../picture/scrollImage1.png';
import populationBackImg from '../../../picture/solutionPage/population/2021-03-11-161456_1145x639_scrot.png';
import rentalBackImg from '../../../picture/solutionPage/rental/backgroundImg.png';
import './changeSences.scss';

export default function ChangeSences() {
    let history = useHistory();
    const jumpTo = (path) => history.push(path);
    const changesInfo = [
        {
            firstTitle: '智慧社区安防系统',
            secondTitle: '高效全链路解决方案',
            backImg: BackImg,
            imgName: 'scrollImage1.png',
            path: '/solutions/smart',
        },
        {
            firstTitle: '昆明流动人口数字大脑',
            secondTitle: '基于大数据分析支持的全面解决方案',
            backImg: BackImg,
            path: '/solutions/populations',
        },
        {
            firstTitle: '出租房屋智能化管理',
            secondTitle: '房屋，房客，房东全链路解决方案',
            backImg: BackImg,
            path: '/solutions/rental',
        },
        {
            firstTitle: '停车智能化',
            secondTitle: '车辆识别，车位管理一体解决方案',
            backImg: BackImg,
            path: '/solutions/park',
        },
        {
            firstTitle: '自研核心科技',
            secondTitle: '全域产品持续护航',
            backImg: BackImg,
            path: '/solutions/selfTec',
        },
    ];
    const { path, params } = useRouteMatch();
    const status = Number(sessionStorage.getItem('changesStatus'));
    useEffect(() => {
        return () => {
            sessionStorage.setItem('changesStatus', 0);
        };
    }, []);
    return (
        <div className="mainContainer">
            {changesInfo.map((item, index) => {
                return (
                    <div
                        className={
                            status === index
                                ? 'imgTitleContainer'
                                : 'hideImgTitleContainer'
                        }
                        key={item + index}
                    >
                        <img src={item.backImg} alt={item.firstTitle} />
                        <span className="firstTitle">{item.firstTitle}</span>
                        <br />
                        <span className="secondTitle">{item.secondTitle}</span>
                    </div>
                );
            })}
            <div className="buttonBar">
                {changesInfo.map((item, index) => {
                    return (
                        <button
                            key={index}
                            onClick={() => {
                                sessionStorage.setItem('changesStatus', index);
                                jumpTo(item.path);
                                console.log(path);
                                console.log(params);
                            }}
                            className={
                                status === index
                                    ? 'showInitialButton'
                                    : 'otherButton'
                            }
                        >
                            {item.firstTitle}
                        </button>
                    );
                })}
            </div>
        </div>
    );
}
