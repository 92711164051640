import React from 'react';

import './app.scss';
import { Container } from './repository/repository';
import { HashRouter as Router, Route } from 'react-router-dom';
import FirstPage from './page/firstPage/FirstPage';
import SolutionsPage from './page/solutionsPage/SolutionsPage';
import ProductPage from './page/productPage/ProductPage';
import AboutUsPage from './page/aboutUsPage/AboutUsPage';

export default function App() {
    return (
        <div className="appContainer">
            <Container.Provider>
                <Router>
                    <Route path="/" exact component={FirstPage} />
                    <Route path="/solutions/:name?" component={SolutionsPage} />
                    <Route
                        path="/product/:productName?"
                        component={ProductPage}
                    />
                    <Route
                        // path="/aboutUs/:aboutDetail?"
                        path="/aboutUs/:aboutUsName?"
                        component={AboutUsPage}
                    />
                </Router>
            </Container.Provider>
        </div>
    );
}
