import React from 'react';

const styles = require('./selfPlatformComponent.module.scss');
export default function SelfPlatformComponent({ selfPlatformInfo }) {
    return (
        <div className={styles.mainContainer}>
            <div className={styles.titleContainer}>
                <p className={styles.title}>{selfPlatformInfo.title}</p>
                <p className={styles.englishTitle}>
                    {selfPlatformInfo.englishTitle}
                </p>
            </div>
            <p className={styles.summary}>{selfPlatformInfo.summary}</p>
            {selfPlatformInfo.platformMember.map((item, index) => {
                return (
                    <div className={styles.itemContainer} key={item + index}>
                        <img src={item.img} alt={item.platformName} />
                        <div className={styles.rightContainer}>
                            <p className={styles.platformName}>
                                {item.platformName}
                            </p>
                            <p className={styles.platformDescribe}>
                                {item.platformDescribe}
                            </p>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
