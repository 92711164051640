import React from 'react';
import imgOne from '../../../picture/businessSence/pay.png';
import imgTwo from '../../../picture/businessSence/smartCity.png';
import imgThree from '../../../picture/businessSence/bigData.png';

const styles = require('./smartParkDescribe.module.scss');

export default function SmartParkDescribe() {
    const smartParkDescribeInfo = {
        summaryTitle: '方案概述',
        summaryDescribe:
            '停车智能化建设核心为给车主提供更加安全、舒适、方便、快捷和开放的环境，实现停车场运行的高效化、节能化、环保化，并解决实际业务问题。',
        summaryImg: [imgOne, imgTwo, imgThree],
        innovationDescribe: [
            '例如：用户停车耗时久体验差，平台建设信息化程度低，停车数据管理规范匮乏，联网停车场数据整合程度低等',
            '方案采用集中监控、远程管理的方式，可通过手机、电脑远程查看车场监控， 车辆信息实时掌握；通过车牌自动识别、快速抬杆、提高通行效率，提升用户体验，高精度车牌识别系统+云平台监控，实现岗亭 无人值守，降低人力成本，车位费用支持微信、支付宝、银联等线上支付渠道 以及后台支付、线下支付，并通过通过大数据分析系统，自动生成详细的经营 报表，为车场经营提供决策支持，后期还支持个性化流程管理，满足商业广场、超市、 酒店等停车管理特殊需求。',
        ],
    };

    return (
        <div className={styles.mainContainer}>
            <p className={styles.summaryTitle}>
                {smartParkDescribeInfo.summaryTitle}
            </p>
            <p className={styles.summaryDescribe}>
                {smartParkDescribeInfo.summaryDescribe}
            </p>
            <div className={styles.mapContainer}>
                {smartParkDescribeInfo.summaryImg.map((item, index) => {
                    return (
                        <img
                            className={styles.summaryImg}
                            src={item}
                            alt={`summaryImg${index}`}
                            key={item + index}
                        />
                    );
                })}
            </div>
            <p className={styles.innovationDescribeOne}>
                {smartParkDescribeInfo.innovationDescribe[0]}
            </p>
            <p className={styles.innovationDescribeTwo}>
                {smartParkDescribeInfo.innovationDescribe[1]}
            </p>
        </div>
    );
}
