import React from 'react';

const styles = require('./rightImgDisplay.module.scss');
export default function RightImageDisplay({ imageInfo }) {
    return (
        <div className={styles.mainContainer}>
            {imageInfo.map((item, index) => {
                return (
                    <div className={styles.cubeContainer} key={item + index}>
                        <img src={item.img} alt={item.title} />
                        <span>{item.title}</span>
                    </div>
                );
            })}
        </div>
    );
}
