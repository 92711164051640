import React from 'react';
import partnerImg from '../../../../picture/partner.png';

const styles = require('./partner.module.scss');
export default function Partner() {
    return (
        <div className={styles.mainContainer}>
            <p>我们的合作伙伴</p>
            <img src={partnerImg} alt="partnerImg" />
        </div>
    );
}
