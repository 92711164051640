import React from 'react';
import imgOne from '../../../picture/businessSence/pay.png';
import imgTwo from '../../../picture/businessSence/smartCity.png';
import imgThree from '../../../picture/businessSence/bigData.png';

const styles = require('./rentalDescribe.module.scss');

export default function RentalDescribe() {
    const rentalDescribeInfo = {
        summaryTitle: '方案概述',
        summaryDescribe:
            '合规化、智能化、一体化，实人认证承租人身份，出租房屋流动人口自动申报，一键实施实名管控，合法合规；通过手机即可实现租客管理、房间管理、租约管理、费用管理、安全预警等场景操作;为房屋管理提供软硬件一体化的完整解决方案',
        summaryImg: [imgOne, imgTwo, imgThree],
        innovationTitle: '租住生活新凤舞',
        innovationDescribe: [
            '采用金融级安全标准锁芯，顶级安全技术接入，有效防止技术与暴力破解；实现手机自主看房，更改密码，查看、缴纳房租和水电费等实际场景操作；基于“可信身份认证平台“实人开门， 坏人寸步难行，好人更加安全；为“新居民”提供一键办理居住证等政务服务，找工作、保洁、社区购物等本地生活新服务，全面提高住户幸福感安全感',
        ],
    };

    return (
        <div className={styles.mainContainer}>
            <p className={styles.summaryTitle}>
                {rentalDescribeInfo.summaryTitle}
            </p>
            <p className={styles.summaryDescribe}>
                {rentalDescribeInfo.summaryDescribe}
            </p>
            <div className={styles.mapContainer}>
                {rentalDescribeInfo.summaryImg.map((item, index) => {
                    return (
                        <img
                            className={styles.summaryImg}
                            src={item}
                            alt={`summaryImg${index}`}
                            key={item + index}
                        />
                    );
                })}
            </div>
            <p className={styles.innovationTitle}>
                {rentalDescribeInfo.innovationTitle}
            </p>
            {rentalDescribeInfo.innovationDescribe.map((item, index) => {
                return (
                    <p className={styles.innovationDescribe} key={item + index}>
                        {item}
                    </p>
                );
            })}
        </div>
    );
}
