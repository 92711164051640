import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import TopBar from '../commonComponent/TopBar';
import TopView from './pageComponent/TopView';
import SwitchBar from '../commonComponent/SwitchBar';
import BussinessTalk from '../commonComponent/BussinessTalk';
import BottomComponent from '../commonComponent/BottomComponent';
import PlatformFeatures from '../productPage/productCommonComponent/PlatformFeatures';
import { productSwitchBarInfo } from './infoConfig/productSwitchBarConfig';

const styles = require('./productPage.module.scss');

export default function ProductPage() {
    const [status, setStatus] = useState(0);
    const { url } = useRouteMatch();
    useEffect(() => {
        const router = url.split('/');
        router[1] === 'product'
            ? sessionStorage.setItem('topBarStatus', 2)
            : console.log('product router wrong');
        setStatus(status + 1);
        router[2] === 'smart'
            ? sessionStorage.setItem('switchBar', 0)
            : router[2] === 'population'
            ? sessionStorage.setItem('switchBar', 1)
            : router[2] === 'pay'
            ? sessionStorage.setItem('switchBar', 2)
            : router[2] === 'park'
            ? sessionStorage.setItem('switchBar', 3)
            : router[2] === 'selfTec'
            ? sessionStorage.setItem('switchBar', 4)
            : console.log('product router params wrong');
    }, [url]);
    return (
        <div className={styles.mainContainer}>
            <TopBar />
            <TopView />
            <SwitchBar switchBarInfo={productSwitchBarInfo} />
            <PlatformFeatures />
            <BussinessTalk />
            <BottomComponent />
        </div>
    );
}
