import smartCommunityImg from '../../../picture/solutions/communitySecurity/securityDeiailImgOne.png';
import smartRightImgOne from '../../../picture/solutions/communitySecurity/securityDetailImageTwo.png';
import smartRightImgTwo from '../../../picture/solutions/floatingPopulation/detailImageOne.png';
import smartRightImgThree from '../../../picture/solutions/floatingPopulation/detailImageTwo.png';

export const smartCommunityInfo = {
    platformImg: smartCommunityImg,
    platformName: '智慧社区建设',
    platformEnglistName: 'Smart community',
    platformTags: [
        '全面安防保护',
        '自由平台支持',
        '多年建设经验',
        '全链路方案',
    ],
    platformSummary:
        '智慧社区是指充分利用物联网、云计算、移动互联网等新一代信息技术的集成应用,为社区居民提供一个安全、舒适、便利的现代化、智慧化生活环境,从而形成基于信息化、智能化社会管理与服务的一种新的管理形态的社区。',
    platformRightImg: [
        { title: '社区安防', img: smartRightImgOne },
        { title: '智慧物业', img: smartRightImgTwo },
        { title: '智慧家居', img: smartRightImgThree },
    ],
    platformFeatureTitle: '易乾科技智慧社区包含以下几个方面：',
    platformFeaturePoints: [
        {
            number: 1,
            title: '智慧物业管理:',
            describe:
                '针对智慧化社区的特点，集成物业管理的相关系统，例如：停车场管理、闭路监控管理、门禁系统、智能消费、电梯管理、保安巡逻、远程抄表，自动喷淋等相关社区物业的智能化管理，实现社区各独立应用子系统的融合，进行集中运营管理。',
        },
        {
            number: 2,
            title: '电子商务服务：',
            describe:
                '社区电子商务服务是指是在社区内的商业贸易活动中，实现消费者的网上购物、商户之间的网上交易和在线电子支付以及各种商务活动、交易活动、金融活动和相关的综合服务活动，区居民无需出门即可无阻碍的完成绝大部分生活必需品的采购。',
        },
        {
            number: 3,
            title: '智慧养老服务：',
            describe:
                '现在老人居住的环境有两种最常见，一是住在家里，另外就是住在养老院，针对这两种情况分别提出智慧养老的方案其最终宗旨是使得老人有安全保障，子女可以放心工作，政府方便管理。家庭“智慧养老”实际上就是利用物联网技术，通过各类传感器，使老人的日常生活处于远程监控状态。',
        },
        {
            number: 4,
            title: '智慧家居：',
            describe:
                '智慧家居是以住宅为平台，兼备建筑、网络通信、信息家电、设备自动化，集系统、结构、服务、管理为一体的高效、舒适、安全、便利、环保的居住环境。',
        },
    ],
};
export const populationInfo = {
    platformImg: smartCommunityImg,
    platformName: '流动人口治理',
    platformEnglistName: 'Floating population governance',
    platformTags: ['政企合作', '高效管控', '可视化大数据', '人房合一'],
    platformSummary:
        '城市的流动人口数量大、来源杂、层级多，基层治理压力大，综合治理资源消耗大，在社会治理现代化要求下，亟需“升级流动人口权益、促进服务管理效能、提升综合治理能力”。',
    platformRightImg: [
        { title: '智能门锁', img: smartRightImgOne },
        { title: '大数据管控', img: smartRightImgTwo },
    ],
    platformDescribe: [
        '我们创新实践基于出租房屋智能门锁等物联终端，“投、建、运”一体的政企合作“数字新基建”模式，“政府主导、企业主投、基层共治”的多方 共建，运用AI、大数据、物联网和云计算等先进技术，摸清真实“底数”，以锁管房，以房管人，实现人、物、空间的数字化管理，通过“民生服 务+平安综治”数字化双轮驱动，完成主管政府机构、企业、租户等全链路流动人口智能化管理服务。真正以“一个平台，两个创新模式，三个数 字化升级”的顶层设计，构建全域流动人口数字化治理体系，提升城市数字化治理能力和治理水平。',
        '并运营AI、大数据等技术，提供多主题数据大屏展示和管控服务，支持多端呈现。通过实时掌控辖区内基础常态管理、异常动态管理，提供分析 决策支撑，实现“一目了然，实时决策，精准服务”的多维度数字治理服务。',
    ],
};
export const payInfo = {
    platformImg: smartCommunityImg,
    platformName: '聚合支付平台',
    platformEnglistName: 'Aggregate payment platform',
    platformTags: ['多支付平台', '费率多样可选', '安全可靠'],
    platformSummary:
        '聚合支付是指只从事“支付、结算、清算”服务之外的“支付服务”，依托银行、非银机构或清算组织，借助银行、非银机构或清算组织的支付通道与清结算能力，利用自身的技术与服务集成能力，将一个以上的银行、非银机构或清算组织的支付服务，整合到一起，以此减少商户接入、维护支付结算服务时面临的成本支出，提高商户支付结算系统运行效率的。',
    platformRightImg: [
        { title: '安全稳定', img: smartRightImgOne },
        { title: '多平台聚合', img: smartRightImgTwo },
    ],
    platformFeaturePoints: [
        {
            number: 1,
            describe:
                '易乾拥有多年支付服务经验，专注互联网行业。超低、灵活的接口服务费机制。快速注册，秒对接，单一收费模式，无其他任何隐形增值费用。服务费率低。给你更快更好的支付体验。资金直达商户收款账号，平台不清算不托管，无需担心平台跑路!',
        },
        {
            number: 2,
            describe:
                '简单的操作步骤，简洁的界面布局，傻瓜式操作流程，让您使用更方便，告别繁琐的业务流程',
        },
        {
            number: 3,
            describe:
                '数据透明清晰，多重数据检索保证数据准确，人性化数据透视界面，对流水数据一目了然。个人微信支付宝账号即可收款，即开即用高并发，超稳定不漏单！',
        },
    ],
};
export const parkInfo = {
    platformImg: smartCommunityImg,
    platformName: '流动人口治理',
    platformEnglistName: 'Intelligent parking',
    platformTags: [
        '全面安防保护',
        '自由平台支持',
        '多年建设经验',
        '全链路方案',
    ],
    platformSummary:
        '智慧社区是指充分利用物联网、云计算、移动互联网等新一代信息技术的集成应用,为社区居民提供一个安全、舒适、便利的现代化、智慧化生活环境,从而形成基于信息化、智能化社会管理与服务的一种新的管理形态的社区。',
    platformRightImg: [
        { title: '智能导航', img: smartRightImgOne },
        { title: '进准定位', img: smartRightImgTwo },
    ],
    platformDescribe: [
        '易乾科技智能停车平台采用高清车牌识别摄像机对进入停车场的车辆进行车牌识别、图像抓拍，将车牌信息传输给对应的控制器，再上传给电 脑、引导车辆进入,并保存记录；在停车场出口通过高清车牌识别摄像机对驶出的年辆进行车牌识别、图像抓拍，在线状态通过计算机判断，对 固定车自动放行；脱机状态有停车场控制器判断，对固定车辆放行，并保存记录，如果系统中有语音和显示屏，会驱动其播报和显示车辆信息。 对于临时车根据停车时间进行管理,实现车辆的进出监控和管理。',
        '同时自动储存用户临时停车视频和图像记录，为处理违章停车，追缴逃费、漏费提供了完整的证据链，解决了传统停车管理效率低、依赖人工、 操作繁琐、用户体验感差等问题。',
    ],
};
export const selfPlatformInfo = {
    title: '自研开放平台',
    englishTitle: 'Self research open platform',
    summary:
        '易乾科技一直注重科创新，在云南本土已经具备一定的业界知名度和实力；并自主研发了多个先进的开放平台。',
    platformMember: [
        {
            platformName: '开放平台1',
            img: smartRightImgOne,
            platformDescribe:
                '项目介绍文案项目介绍文案项目介绍文案项目介绍文案项目介绍文案项目介绍文案项目介绍文案项目介绍文案项目介绍文案项目介绍文案项目介绍文案项',
        },
        {
            platformName: '开放平台2',
            img: smartRightImgThree,
            platformDescribe:
                '项目介绍文案项目介绍文案项目介绍文案项目介绍文案项目介绍文案项目介绍文案项目介绍文案项目介绍文案项目介绍文案项目介绍文案项目介绍文案项',
        },
    ],
};
