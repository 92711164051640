import React from 'react';

import imgOne from '../../../picture/solutions/rentalMangement/rentalImgOne.png';
import imgTwo from '../../../picture/solutions/rentalMangement/rentalImgTwo.png';
import imgThree from '../../../picture/solutions/smartPark/parkImageOne.png';

const styles = require('./innovationComponent.module.scss');
export default function InnovationComponent() {
    const innovationInfo = {
        title: '科技创新',
        describe:
            '易乾科技一直注重科创新，在云南本土已经具备一定的业界知名度和实力；并自主研发了先进的线上支付平台和，智慧停车系统，在客户群体中收货了良好的口碑。',
        innovationMember: [
            {
                name: '智慧支付平台',
                describe:
                    '项目介绍文案项目介绍文案项目介绍文案项目介绍文案项目介绍文案项目介绍文案项目介绍文案项目介绍文案项目介绍文案项目介绍文案项目介绍文案项',
                img: imgOne,
            },
            {
                name: '智慧支付平台',
                describe:
                    '项目介绍文案项目介绍文案项目介绍文案项目介绍文案项目介绍文案项目介绍文案项目介绍文案项目介绍文案项目介绍文案项目介绍文案项目介绍文案项',
                img: imgTwo,
            },
            {
                name: '智慧支付平台',
                describe:
                    '项目介绍文案项目介绍文案项目介绍文案项目介绍文案项目介绍文案项目介绍文案项目介绍文案项目介绍文案项目介绍文案项目介绍文案项目介绍文案项',
                img: imgThree,
            },
        ],
    };
    return (
        <div className={styles.mainContainer}>
            <p className={styles.title}>{innovationInfo.title}</p>
            <p className={styles.describe}>{innovationInfo.describe}</p>
            <div className={styles.memberContainer}>
                {innovationInfo.innovationMember.map((item, index) => {
                    return (
                        <div
                            className={styles.itemContainer}
                            key={item + index}
                        >
                            <img src={item.img} alt={item.name} />
                            <div className={styles.rightContainer}>
                                <p className={styles.rightTitle}>{item.name}</p>
                                <p className={styles.rightDescribe}>
                                    {item.describe}
                                </p>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
