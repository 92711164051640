import React, { useState } from 'react';
// import { Container } from '../../repository/repository';

import doubleArrow from '../../../../picture/adv/doubleDownArrow.png';
import cubeLogo from '../../../../picture/adv/blue/cubeLogo.png';
import cubeHover from '../../../../picture/adv/white/cubeHover.png';
import teamLogo from '../../../../picture/adv/blue/team.png';
import teamHover from '../../../../picture/adv/white/teamHover.png';
import heartLogo from '../../../../picture/adv/blue/heartLogo.png';
import heartHover from '../../../../picture/adv/white/heartHover.png';
import likeLogo from '../../../../picture/adv/blue/likesLogo.png';
import likeHover from '../../../../picture/adv/white/likeHover.png';

const styles = require('./advances.module.scss');

export default function Advances() {
    // const repository = Container.useContainer();
    const advArr = [
        {
            logo: cubeLogo,
            hoverLogo: cubeHover,
            title: '10年专业领域经验',
            totalDescribe: '良好的口碑和知名度',
            detailDescribe:
                '我们拥有一批多元化专业人才,可以\n从多个领域多个角度解决问题,进行最新技\n术开发和解决难题,并同时进行人才\n孵化和团队扩充',
        },
        {
            logo: teamLogo,
            hoverLogo: teamHover,
            title: '强大的技术团队',
            totalDescribe: '多元化的专业技术团队',
            detailDescribe:
                '我们拥有一批多元化专业人才,可以\n从多个领域多个角度解决问题,进行最新技\n术开发和解决难题,并同时进行人才\n孵化和团队扩充',
        },
        {
            logo: heartLogo,
            hoverLogo: heartHover,
            title: '全心全意为客户服务',
            totalDescribe: '以客户的真实诉求为核心',
            detailDescribe:
                '多年来我公司秉承“诚信为本，实力\n为先，全心全意为客户服务”的经营理\n念，全天专业人员随时为您候命，更有\n在线客服12小时为您答疑解惑',
        },
        {
            logo: likeLogo,
            hoverLogo: likeHover,
            title: '诚信为本，实力为先',
            totalDescribe: '众多伙伴的认可和长期合作',
            detailDescribe:
                '凭着过硬的技术、高质量的产品和完善的\n服务体系，我司现已成为中国建设银行云南省\n分行的指定供货商和服务商，业务以昆明市\n为中心覆盖全省，并逐年增加。',
        },
    ];
    const [hoverIndex, setHoverIndex] = useState(0);
    return (
        <div
            className={
                // repository.topBarIndex === 0
                //     ? styles.container
                //     : styles.hideContainer
                styles.container
            }
        >
            <p>企业核心优势</p>
            <div className={styles.mapContainer}>
                {advArr.map((item, index) => {
                    return (
                        <div
                            className={styles.mapCube}
                            key={index}
                            onMouseEnter={() => {
                                setHoverIndex(index + 1);
                            }}
                            onMouseLeave={() => {
                                setHoverIndex(0);
                            }}
                        >
                            <div className={styles.topBlueLine}></div>
                            <img
                                className={styles.doubleArrow}
                                src={doubleArrow}
                                alt="doubleArrow"
                            />
                            <img
                                className={styles.advLogo}
                                src={
                                    hoverIndex - 1 === index
                                        ? item.hoverLogo
                                        : item.logo
                                }
                                alt="advLogo"
                            />
                            <p className={styles.mapCubeTitle}>{item.title}</p>
                            <p className={styles.mapCubeTotalDescribe}>
                                {item.totalDescribe}
                            </p>
                            <div className={styles.separator}>
                                <div className={styles.separatorOne}></div>
                                <div className={styles.separatorTwo}></div>
                                <div className={styles.separatorThree}></div>
                            </div>
                            <p className={styles.mapCubeDetailDescribe}>
                                {item.detailDescribe}
                            </p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
