import React from 'react';

import imgOne from '../../../picture/solutions/communitySecurity/securityDeiailImgOne.png';
import imgTwo from '../../../picture/solutions/communitySecurity/securityDetailImageTwo.png';
import imgThree from '../../../picture/solutions/rentalMangement/rentalImgOne.png';

const styles = require('./bussinessComponent.module.scss');

export default function BussinessComponent() {
    const bussinessInfo = {
        title: '主营业务',
        describe:
            '公司经过10多年不断发展，在云南本土已经具备一定的业界知名度和实力，项目遍布云南省内各地，服务领域涉及住宅小区、医院、政府机构、商业广场、机场等智能化停车系统、楼宇对讲、监控安防，在广大客户中树立了良好的口碑及印象，深受用户好评\n我公司品牌“易乾安特”相关产品线经过多年研发完善已趋于完美，在业内知名度颇高。',
        imgMember: [
            {
                firstTitle: '智慧城市建设',
                secondTitle: '全链路解决方案',
                backImg: imgOne,
            },
            {
                firstTitle: '智慧社区安防建设',
                secondTitle: '全链路解决方案',
                backImg: imgTwo,
            },
            {
                firstTitle: '人口大数据治理',
                secondTitle: '全链路解决方案',
                backImg: imgThree,
            },
            {
                firstTitle: '智能停车场建设',
                secondTitle: '全链路解决方案',
                backImg: imgOne,
            },
            {
                firstTitle: '布控地区安防建设',
                secondTitle: '全链路解决方案',
                backImg: imgTwo,
            },
            {
                firstTitle: '大数据分析平台建设',
                secondTitle: '全链路解决方案',
                backImg: imgThree,
            },
            {
                firstTitle: '数字支付解决方案',
                secondTitle: '全链路解决方案',
                backImg: imgOne,
            },
            {
                firstTitle: '出租屋数字管理建设',
                secondTitle: '全链路解决方案',
                backImg: imgTwo,
            },
        ],
    };

    return (
        <div className={styles.mainContainer}>
            <p className={styles.bussinessTitle}>{bussinessInfo.title}</p>
            <p className={styles.bussinessDescribe}>{bussinessInfo.describe}</p>
            <div className={styles.bussinessCubeContainer}>
                {bussinessInfo.imgMember.map((item, index) => {
                    return (
                        <div
                            className={styles.bussinessCube}
                            key={item + index}
                        >
                            <img src={item.backImg} alt="backImg" />
                            <p className={styles.firstTitle}>
                                {item.firstTitle}
                            </p>
                            <p className={styles.secondTitle}>
                                {item.secondTitle}
                            </p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
